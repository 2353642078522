import React, { KeyboardEvent, useEffect, useRef, useState } from 'react'
import InfoTip from './InfoTip'





const TierProperties = {
  tier1: {
    tierName: 'Tier 1',
    noOfHHsValue: '0',
    kwhPerDayValue: '0.012',
    isStandaloneValue: false,
    dollarPerStandaloneValue: '0'
  },
  tier2: {
    tierName: 'Tier 2',
    noOfHHsValue: '0',
    kwhPerDayValue: '0.2',
    isStandaloneValue: false,
    dollarPerStandaloneValue: '0'
  },
  tier3: {
    tierName: 'Tier 3',
    noOfHHsValue: '0',
    kwhPerDayValue: '1',
    isStandaloneValue: false,
    dollarPerStandaloneValue: '0'
  },
  tier4: {
    tierName: 'Tier 4',
    noOfHHsValue: '0',
    kwhPerDayValue: '3.4',
    isStandaloneValue: false,
    dollarPerStandaloneValue: '0'
  },
  tier5: {
    tierName: 'Tier 5',
    noOfHHsValue: '0',
    kwhPerDayValue: '8.2',
    isStandaloneValue: false,
    dollarPerStandaloneValue: '0'
  }
}

const TierRow = ({ tierDetails, tierName, onParentChange }) => {

  const [state, setState] = useState(tierDetails)
  const [error, setError] = useState(false)

  //useEffect(() => {
  //  setState(noOfHHsValue.toString())
  //}, [noOfHHsValue])

  const handleChange = (e, name) => {

    let newState = { ...state }
    newState[name] = e.target.value
    setState(newState)
    onParentChange(tierName, newState);
    setError(false)
  }
  
  const checkboxChange = (e, name) => {
    let newState = { ...state }
    newState[name] = newState[name] == false ? true : false
    setState(newState)
    onParentChange(tierName, newState);
    setError(false)

  }

  return (
    <div className="UL-R-row">
      <div className="UL-R-cell UL-tier-wd10p" data-title="Tiers"> {state.tierName} </div>
      <div className="UL-R-cell" data-title="# of HHs"> <input type="number" value={state.noOfHHsValue} className='form-control field-small' onChange={(e) => handleChange(e, 'noOfHHsValue')}></input> </div>
      <div className="UL-R-cell" data-title="kWh/day/HH"> <input type="number" value={state.kwhPerDayValue} className='form-control field-small' onChange={(e) => handleChange(e, 'kwhPerDayValue')}></input> </div>
      <div className="UL-R-cell" data-title="Standalone System"> <input type="checkbox" value={state.isStandaloneValue} onChange={(e) => checkboxChange(e, 'isStandaloneValue')}></input> </div>
      <div className="UL-R-cell" data-title="$/Standalone System"> <input type="number" value={state.dollarPerStandaloneValue} onChange={(e) => handleChange(e, 'dollarPerStandaloneValue')} className='form-control field-small'></input> </div>
    </div>
  )
}

let HouseHoldTiers = {}

const HouseholdTable = () => {

  const [state, setState] = useState(TierProperties)
  const [total, setTotal] = useState(0)


  const onParentChange = (tierName, tierDetails) => {
    let newState = { ...state }
    console.log(tierDetails, 'onparentchange tier details')
    newState[tierName] = tierDetails
    setState(() => newState)
    caculateTotal(newState)
    HouseHoldTiers = newState
  }

  const caculateTotal = (newState) => {
    console.log(newState.tier1.isStandaloneValue, 'calculate total')
    console.log(newState.tier2.isStandaloneValue, 'calculate total')
    console.log(newState.tier3.isStandaloneValue, 'calculate total')
    console.log(newState.tier4.isStandaloneValue, 'calculate total')
    console.log(newState.tier5.isStandaloneValue, 'calculate total')

    let totalCost = 0

    if (newState.tier1.isStandaloneValue ) {
      totalCost += newState.tier1.noOfHHsValue * newState.tier1.dollarPerStandaloneValue
    }
    if (newState.tier2.isStandaloneValue) {
      totalCost += newState.tier2.noOfHHsValue * newState.tier2.dollarPerStandaloneValue
    }
    if (newState.tier3.isStandaloneValue) {
      totalCost += newState.tier3.noOfHHsValue * newState.tier3.dollarPerStandaloneValue
    }
    if (newState.tier4.isStandaloneValue) {
      totalCost += newState.tier4.noOfHHsValue * newState.tier4.dollarPerStandaloneValue
    }
    if (newState.tier5.isStandaloneValue) {
      totalCost += newState.tier5.noOfHHsValue * newState.tier5.dollarPerStandaloneValue
    }
    setTotal(() => totalCost)
  }

  return (
    <div className="UL-R-table UL-wd-100p UL-d-table col-form-label-css">
      <div className="UL-R-row UL-R-header UL-fontweight-600 tx-black ULbgF WB-Fw-500 UL-R-header-bg">
        <div className="UL-R-cell UL-tier-wd10p">	MTF Tiers </div>
        <div className="UL-R-cell"> Number of Households </div>
        <div className="UL-R-cell"> kWh/day/household
          <div className='d-flex flex-column justify-content-center'>
            <InfoTip id='kWhdayhousehold' text='The default value represents the minimum electricity consumption. The value can range between 12 and 200 Wh/day for tier1; 200 Wh/day and 1 kWh/day for tier2; 1 and 3.4 kWh/day for tier3; 3.4 and 8.2 kWh/day for tier4; and over 8.2 kWh/day for tier5.' />
          </div>
        </div>
        <div className="UL-R-cell"> Connected via Standalone System
          <div className='d-flex flex-column justify-content-center'>
            <InfoTip id='standalonesystem' text='Please check this box if users are to be connected via Standalone Systems rather than through the mini-grid' />
          </div>
        </div>
        <div className="UL-R-cell"> Cost per Standalone System $USD
          <div className='d-flex flex-column justify-content-center'>
            <InfoTip id='standalonesystem' text='Please provide an estimate of the total cost of purchasing a Standalone System, excluding maintenance' />
          </div>
        </div>
      </div>
      <TierRow tierDetails={state.tier1} tierName='tier1' onParentChange={onParentChange} />
      <TierRow tierDetails={state.tier2} tierName='tier2' onParentChange={onParentChange} />
      <TierRow tierDetails={state.tier3} tierName='tier3' onParentChange={onParentChange} />
      <TierRow tierDetails={state.tier4} tierName='tier4' onParentChange={onParentChange} />
      <TierRow tierDetails={state.tier5} tierName='tier5' onParentChange={onParentChange} />
      
      <div className="table-secondary WP-total Ul-d-flex UL-pd-1015 UL-mg-t-5px">
        <div className='UL-wd-50p'><strong>Total cost of standalone systems $USD</strong></div>
        <div className="text-right UL-wd-50p"><strong>{total}</strong></div>
      </div>
    </div>)
}


export { HouseHoldTiers };

export default HouseholdTable;
